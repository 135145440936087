import { useEffect, useCallback, useState } from 'react'

export function useBlocker (blocker, when = true) {
  useEffect(() => {
    if (!when) return

    const handleBeforeUnload = event => {
      const confirmationMessage = blocker()
      if (confirmationMessage) {
        event.preventDefault()
        event.returnValue = confirmationMessage
        return confirmationMessage
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [blocker, when])
}

export function usePrompt (message, when = true, setIsBlocking, setUrl) {
  const [saveData, setSaveData] = useState(false)

  const blocker = useCallback(() => {
    if (when && window.confirm(message)) {
      setIsBlocking(false)
      setSaveData(true)
      return null // Allow navigation
    } else {
      return message // Block navigation
    }
  }, [message, when])

  useBlocker(blocker, when)

  return {
    saveData
  }
}
