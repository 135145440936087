import { createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../axiosConfig'

const initialState = {
  unifiedIdData: [],
  connector: [],
  loading: false,
  connectorFields: {}
}

const unifiedIdSlice = createSlice({
  name: 'unifiedId',
  initialState: initialState,
  reducers: {
    updateUnifiedIdData: (state, action) => {
      state.unifiedIdData = action.payload
    },
    updateConnector: (state, action) => {
      state.connector = action.payload
    },
    updateLoading: (state, action) => {
      state.loading = action.payload
    },
    updateConnectorFields: (state, action) => {
      state.connectorFields = action.payload
    }
  }
})

export const fetchUnifiedIdData = crmSelected => {
  return async (dispatch, getState) => {
    const { unifiedId: { connector } = {} } = getState() || {}
    dispatch(unifiedIdSlice.actions.updateLoading(true))
    let params = {}
    if (!crmSelected.includes('all_displayed')) {
      const connectorIds = connector
        .filter(({ name }) => crmSelected.includes(name))
        .map(({ id }) => id)

      params = {
        connector_ids: `[${connectorIds.join(',')}]`
      }
    }
    const queryParams = new URLSearchParams(params).toString()
    let { data, status } = await axiosInstance.get(
      `/unified_id_fields?${queryParams}`
    )
    let { data: connectorData, status: connectorStatus } =
      await axiosInstance.get('/connectors')
    if (status === 200 && connectorStatus === 200) {
      dispatch(unifiedIdSlice.actions.updateLoading(false))
      dispatch(unifiedIdSlice.actions.updateUnifiedIdData(data))
      dispatch(unifiedIdSlice.actions.updateConnector(connectorData))
    } else {
      dispatch(unifiedIdSlice.actions.updateLoading(false))
    }
  }
}

export const updateUnifiedData = modifiedData => {
  return async dispatch => {
    const data = {
      unified_id_field: { update_fields: modifiedData, delete_fields: [] }
    }
    const res = await axiosInstance.post(
      'unified_id_fields/update_or_delete',
      data
    )
  }
}

export const fetchConnectorData = connectors => {
  return async dispatch => {
    const dataMap = {} // Object to hold connector data

    try {
      const fetchPromises = connectors.map(async connector => {
        const { id, name } = connector
        const url = `/unified_id_fields/connector_fields?connector_id=${id}`
        const response = await axiosInstance.get(url)
        dataMap[name] = response.data // Assign API response to a key in dataMap
      })

      await Promise.all(fetchPromises) // Wait for all API calls to complete
    } catch (error) {
      console.error('Error fetching connector data:', error)
    }

    dispatch(unifiedIdSlice.actions.updateConnectorFields(dataMap))
  }
}

export const { updateUnifiedIdData, updateContacts } = unifiedIdSlice.actions

export default unifiedIdSlice.reducer
