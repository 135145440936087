/** @jsxImportSource @emotion/react */
import React, { useState, useMemo, useEffect, useRef } from 'react'
import DropdownIcon from '../../../../assets/images/dropdown-caret.png'
import Checkbox from '../../../ui/Checkbox/Checkbox'
import {
  dropdownMenu,
  blanket,
  dropdownSearch,
  optionsPanel,
  chevron,
  button,
  iconStyle,
  checkboxStyle
} from './style'

const Button = ({ onClick, iconAfter, children }) => {
  return (
    <div onClick={onClick} css={button} className='button'>
      {children}
      {iconAfter ? iconAfter : null}
    </div>
  )
}

const ChevronDown = () => (
  <span css={chevron}>
    <img src={DropdownIcon} alt='dropdown-caret' />
  </span>
)

const Menu = props => {
  return <div css={dropdownMenu} {...props} className='dropdown-menu' />
}

const Blanket = props => <div css={blanket} {...props} />

const Dropdown = React.forwardRef(
  ({ children, isOpen, target, onClose, style }, ref) => (
    <div css={[dropdownSearch, style]} ref={ref}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  )
)

const Select = ({
  options,
  onOptionSeleted,
  showValue,
  selectedValue = []
}) => {
  const handleOptionChange = (event, option) => {
    if (option.value === 'all_displayed') {
      if (event.target.checked) {
        onOptionSeleted(options.map(opt => opt.value))
      } else {
        onOptionSeleted([])
      }
    } else {
      const newSelectedValues = event.target.checked
        ? [...selectedValue, option.value]
        : selectedValue.filter(key => {
            return key !== option.value && key !== 'all_displayed'
          })

      onOptionSeleted(newSelectedValues)
    }
  }

  return (
    <div css={optionsPanel}>
      <ul>
        {options.map((option, index) => {
          const { key, value, icon } = option
          return (
            <li key={index}>
              <Checkbox
                onChange={event => handleOptionChange(event, option)}
                index={index}
                checked={selectedValue.includes(value)}
                overrideStyle={checkboxStyle}
              />
              {icon && <img src={icon} css={iconStyle} />}
              <span>{key}</span> {showValue && <span>{value}</span>}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const DropdownWithSearch = React.forwardRef(
  (
    {
      value,
      options,
      onOptionSelected = () => {},
      placeholder,
      showValue,
      style,
      isMulti = false
    },
    ref
  ) => {
    const dropdownRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState(isMulti ? [] : '')

    useEffect(() => {
      setSelectedValue(
        value.includes('all_displayed')
          ? options.map(opt => opt.value)
          : Array.isArray(value)
          ? value
          : []
      )
    }, [value])

    const toggleOpen = () => {
      setIsOpen(!isOpen)
    }

    const onSelectChange = option => {
      toggleOpen()
      onOptionSelected(option)
      setSelectedValue(
        option.length === options.length ? 'all_displayed' : option
      )
    }

    const handleDocumentClick = event => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        event.target.closest('.dropdown-menu') === null
      ) {
        setIsOpen(false)
      }
    }

    useEffect(() => {
      document.addEventListener('click', handleDocumentClick)
      return () => {
        document.removeEventListener('click', handleDocumentClick)
      }
    }, [isOpen])

    return (
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        style={style}
        ref={node => {
          dropdownRef.current = node
          if (ref) {
            if (typeof ref === 'function') {
              ref(node)
            } else {
              ref.current = node
            }
          }
        }}
        target={
          <Button
            iconAfter={<ChevronDown />}
            onClick={toggleOpen}
            isSelected={isOpen}
          >
            {selectedValue.includes('all_displayed') ? (
              <span>All Displayed</span>
            ) : (
              <span
                style={{
                  display: 'inline-block',
                  maxWidth: '85%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {Array.isArray(selectedValue)
                  ? selectedValue.join(', ')
                  : selectedValue}
              </span>
            )}
          </Button>
        }
      >
        <Select
          options={options}
          placeholder={placeholder}
          showValue={showValue}
          onOptionSeleted={onSelectChange}
          selectedValue={selectedValue}
        />
      </Dropdown>
    )
  }
)

export default DropdownWithSearch
