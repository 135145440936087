import { createSlice } from '@reduxjs/toolkit'

import axiosInstance from '../../axiosConfig'

const initialRecordsState = {
  recordsData: [],
  meta: {
    segments: [],
    accounts: [],
    source: []
  },
  loading: false
}

const recordsSlice = createSlice({
  name: 'recordsSlice',
  initialState: initialRecordsState,
  reducers: {
    handleRecordsData (state, action) {
      state.recordsData = action.payload
    },
    updateMetaData (state, action) {
      const updatedData = { ...state.meta, ...action.payload }
      state.meta = updatedData
    },
    updateLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export const getRecordsData = payload => {
  return async dispatch => {
    try {
      dispatch(recordsSlice.actions.updateLoading(true))
      const { status, data } = await axiosInstance.post(
        '/records/view_contacts',
        payload
      )
      if (status === 200) {
        dispatch(recordsSlice.actions.updateLoading(false))
        dispatch(recordsSlice.actions.handleRecordsData(data))
      } else {
        dispatch(recordsSlice.actions.updateLoading(false))
      }
    } catch (err) {
      console.log(err)
      dispatch(recordsSlice.actions.updateLoading(false))
    }
  }
}

export const getSegments = () => {
  return async dispatch => {
    try {
      const { status, data } = await axiosInstance.get('/records/segments')
      if (status === 200) {
        dispatch(recordsSlice.actions.updateMetaData(data))
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export const getAccounts = () => {
  return async dispatch => {
    try {
      const { status, data } = await axiosInstance.get('/records/accounts')
      if (status === 200) {
        dispatch(recordsSlice.actions.updateMetaData(data))
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export const getSources = () => {
  return async dispatch => {
    try {
      const { status, data } = await axiosInstance.get('/connectors')
      if (status === 200) {
        dispatch(recordsSlice.actions.updateMetaData({ source: data }))
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export default recordsSlice.reducer
