import { css } from '@emotion/react'

export const headerContainer = length => css`
  &.ag-header-group-cell-label {
    ${
      length === 2
        ? 'justify-content: flex-end !important; padding-right: 18px;'
        : ''
    }
    .customHeaderLabel {
      position: relative;
      height: 70px;
      width: 70px;
      display: flex;
      border: solid #cccc;
      border-width: 0.8px;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 6px -1px rgb(102 153 204 / 40%);
      img {
        object-fit: contain;
        width: 35px;
        height: 35px;
        &.bamboragroupColumns {
          width: 24px;
        }
      }
      .customExpandButton {
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
        background: #16a3e1;
        border-radius: 5px;
        z-index: 5;
        transition: opacity 0.5s ease-in-out;
      }
      &:hover .customExpandButton {
        opacity: 1;
      }
    }
  }
`

export const minimizerStyle = css`
  display: inline-flex;
  width: 19px;
  height: 19px;
  background: #16a3e1;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
`
