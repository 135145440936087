/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react'
import Remove from '../../../../assets/images/Remove.png'
import DropdownWithSearch from '../../../ui/DropdownWithSearch/DropdownWithSearch'
import InputWithChips from '../../../ui/InputWithChips/InputWithChips'
import Checkbox from '../../../ui/Checkbox/Checkbox'
import { useSelector } from 'react-redux'
import {
  conditionWrapper,
  conditionContainerStyle,
  iconStyle,
  crossIconContainerStyle,
  dropdownStyle,
  inputChipStyle,
  checkboxContainerStyle,
  checkboxStyle,
  dateContainerStyle
} from './style'

const firstDropdownOptions = [
  { key: 'Contact > Segments', type: 'text' },
  { key: 'Contact > Accounts', type: 'text' },
  { key: 'Contact > Source', type: 'text' }
]

const secondDropdownOptions = {
  text: ['equals', 'is not equals'],
  date: ['is less than', 'is greater than']
}

const checkboxOptions = ['is true', 'is false', 'is empty']

const ContactConditionForm = ({
  data,
  ruleIndex,
  ruleGroupIndex,
  updateConditionsData = () => {},
  deleteCondition = () => {}
}) => {
  const { prospectTag, condition, value } = data
  const [filterType, setFilterType] = useState('')
  const [conditionData, setConditionData] = useState({})
  const [thirdDropdownOptions, setThirdDropdownOptions] = useState({})
  const { meta } = useSelector(({ records: { meta } }) => ({
    meta
  }))

  useEffect(() => {
    const { type } =
      firstDropdownOptions.find(({ key }) => key.includes(prospectTag)) || {}
    setFilterType(type)
    setConditionData({
      prospectTag: prospectTag || '',
      condition: condition || '',
      value: value || ''
    })
    let optionKey = 'Segments'
    if (prospectTag) {
      const valueArray = prospectTag.split('>')
      optionKey = valueArray[valueArray.length - 1]
    }
    let options = []
    if ((optionKey.trim() || '') === 'Source') {
      options = meta[(optionKey.trim() || '').toLowerCase()].map(
        ({ name }) => name
      )
    } else {
      options = meta[(optionKey.trim() || '').toLowerCase()]
    }
    setThirdDropdownOptions(options)
  }, [prospectTag, condition, value])

  const handleOnChange = (key, value) => {
    setConditionData(prev => {
      return {
        ...prev,
        [key]: value,
        ...(key === 'prospectTag'
          ? { value: undefined, condition: undefined }
          : {})
      }
    })
    if (key === 'prospectTag') {
      const valueArray = value.split('>')
      const optionKey = valueArray[valueArray.length - 1]
      const options = meta[(optionKey.trim() || '').toLowerCase()]
      setThirdDropdownOptions(options)
    }
    updateConditionsData({ ruleGroupIndex, ruleIndex, key, value })
  }

  const getValueField = useMemo(() => {
    if (conditionData.condition) {
      switch (filterType) {
        case 'text':
          return (
            <InputWithChips
              style={inputChipStyle}
              options={thirdDropdownOptions}
              value={conditionData.value}
              onOptionSelected={value => handleOnChange('value', value)}
            />
          )
        case 'date':
          return (
            <div css={dateContainerStyle}>
              <input
                type='number'
                value={conditionData.value}
                onChange={({ target: { value } }) =>
                  handleOnChange('value', value)
                }
              />{' '}
              days ago
            </div>
          )
        default:
          return null
      }
    }
  }, [filterType, conditionData.condition, conditionData.value])

  const getConditionField = useMemo(() => {
    switch (filterType) {
      case 'text':
        return (
          <DropdownWithSearch
            style={dropdownStyle}
            options={secondDropdownOptions['text']}
            placeholder='Search..'
            value={conditionData.condition}
            onOptionSelected={value => handleOnChange('condition', value)}
          />
        )
      case 'date':
        return (
          <DropdownWithSearch
            style={dropdownStyle}
            options={secondDropdownOptions['date']}
            placeholder='Search..'
            value={conditionData.condition}
            onOptionSelected={value => handleOnChange('condition', value)}
          />
        )
      case 'checkbox':
        return (
          <div css={checkboxContainerStyle}>
            {checkboxOptions.map((option, index) => {
              return (
                <span key={index} css={checkboxStyle}>
                  <Checkbox
                    index={index}
                    checked={(conditionData.value || []).includes(option)}
                    onChange={e => {
                      const previousValues = Array.isArray(conditionData.value)
                        ? [...conditionData.value]
                        : []
                      if (e.target.checked) {
                        previousValues.push(option)
                      } else {
                        const selectedIndex = previousValues.findIndex(
                          item => item === option
                        )
                        previousValues.splice(selectedIndex, 1)
                      }
                      handleOnChange('value', previousValues)
                    }}
                  />{' '}
                  {option}
                </span>
              )
            })}
          </div>
        )
      default:
        return null
    }
  }, [filterType, conditionData.value])

  return (
    <div css={conditionWrapper}>
      <div css={conditionContainerStyle}>
        <DropdownWithSearch
          options={[...firstDropdownOptions].map(({ key }) => key)}
          style={dropdownStyle}
          value={conditionData.prospectTag}
          onOptionSelected={value => {
            const { type } =
              firstDropdownOptions.find(({ key }) => key === value) || {}
            setFilterType(type)
            handleOnChange('prospectTag', value)
            if (type === 'checkbox') {
              handleOnChange('condition', 'checkbox')
            }
          }}
        />
        {getConditionField}
        {getValueField}
      </div>
      <div css={crossIconContainerStyle}>
        <span
          css={iconStyle}
          onClick={() => deleteCondition(ruleGroupIndex, ruleIndex)}
        >
          <img src={Remove} />
        </span>
      </div>
    </div>
  )
}

export default ContactConditionForm
